
 /* app-container {

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
  }  */
  
 .table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
  } 
  .h3 hr {
    margin-top: -10px;
    margin-bottom: -10px;
    width: 900px;
  }
 
  .table th {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
    font-size: 1rem;
    color:black;
   
  }
  .table td {
    /* border: 1px solid black; */
    text-align: left;
    padding: 8px;
    font-size: 18px;

   
  }
.date{
  /* background-color: red; */
  width: 150px;
  margin-top: 75px;
  margin-left: 60px;
  float: left;
}
  .table th {
    background-color:aqua;
    /* border-color: red; */
    width: 200px;
    /* text-align: center;  */
  }
  
  .table td {
    background-color: rgb(205, 235, 253);
  }
  
  .table form {
    display: flex;
    gap: 5px;
  }
  
  .table form td:last-child {
    display: flex;
    justify-content: space-evenly;
  }
  
  .table form * {
    font-size: 0.9rem;

  } 
