.ele{
    width: 220px;
    height: 80px;
    border-radius: 20px;
    /* margin-top: 10px; */
    float: right;
 
 

}

.btn{
    width: 190px;
    height: 80px;
    border-radius: 20px;
    float: right;

}
#dnl{

    width: 220px;
    height: 80px;
    border-radius: 20px;
    float: right;
    margin-right: 10px;
    margin-bottom: 10px;
    

}
button{
    width: 190px;
    height: 70px;
    background-color:darkred;
    border-radius: 40px;
    color:white;
    /* font-weight: bold; */
    font-size: 1.5rem;
margin-top: 5px;

}
.name h3{
    margin-top: 10px;
}
.resume{
    padding: 10px;
    background-color: white;
    border: 1px solid black;
    border-radius: 15px;
}
.btnContainer{
    margin-top: 10px;
    margin-bottom: 10px;
    /* background-color: burlywood; */
   /* float: right; */
   width: 200;
   height: 80px;
}
.name{
    text-align:center;
    /* border: 1px solid black; */
    margin-bottom: 10px;
}
/* .x{
    float: left;
    height: 40px;
    width: 5%;
    border: 1px solid black;
} */
.header{
    height: 40px;
    /* float: left; */
  
       /* display: inline; */

    text-align:center;
    /* border: 1px solid black; */
 
}
.address{
    float: left;
    height: 40px;
    width: 33%;
    display: inline;
    text-align:center;
    /* border: 1px solid black; */
}
.phone{
    float: left;
    height: 40px;
    width: 33%;
    display: inline;
    text-align:center;
    /* border: 1px solid black; */
}
.email{
    display: inline;
    text-align:center;
    float: left;
    height: 40px;
    width: 33%;
/* border: 1px solid black; */
}
.git{
    display: inline;
    text-align:center;
    float: left;
    height: 40px;
    width:50%;
    /* border: 1px solid black; */

}
.link{
    display: inline;
    text-align:center;
    float: left;
    height: 40px;
    width: 50%;
    /* border: 1px solid black; */
    
}
.header2{
    height: 40px;
    /* border: 1px solid black; */
    text-align:center;
}
.qualifications{
     /* border: 1px solid black; */
     margin-bottom: 10px;
}
.qualifications h4{
    margin-left: 10px;
    margin-bottom: 10px;
}
.qualifications  li{
    margin-left: 40px;
    
    line-height: 1.5rem;
    
}
.exp  li{
    margin-left: 30px;
    
    line-height: 1.5rem;
    
}
.projs  li{
    margin-left: 30px;
    
    line-height: 1.5rem;
    
}
.skills{
    margin-left: 10px;
}
.skills h4{
    /* margin-left: 5px; */
    margin-bottom: 10px;
}
.skills h5{
    margin-left: 10px;
    margin-bottom: 10px;
}
.lang{
    margin-left: 10px;
    margin-bottom: 10px;
}
.tools{
    margin-left: 10px;
    margin-bottom: 10px;
}
.tools h5{
    margin-bottom: 5px;
}
.tool{
    margin-top: -5px;
    margin-left: 20px;
    line-height: 1.5rem;
}
hr{
    margin-top: 10px;
    margin-bottom: 10px;
}
.ed{
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    height:200px;
     /* border: 1px solid black; */
}
.eds{
   margin-top: 5px;
   margin-bottom: 5px;

}
.sch{

    margin-left: 10px;


    display: inline;
    text-align:left;
    float: left;
    margin-top: 8px;
    width: 90%;
    margin-bottom: 8px;
}
.ed h5{
    margin-top: 10px;
}

.gpas{


    display: inline;
    text-align:center;
    float: right;
    margin-top: 8px;
    width: 6%;
     margin-bottom: 8px;

}
.exp{
    margin-left: 10px;
    margin-top: 10px;
    /* border: 1px solid black; */
    line-height: 1.5rem;
}
.exp li{
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
}
.exps{
margin-left: 10px;
margin-top: 5px;
margin-bottom: 5px;
}
.proj h4 {
    margin-left: 10px;
}
.projs {
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.5rem;
}
.projs li{
    font-weight: bolder;
}
.work h4{
    margin-left: 10px;
    margin-bottom:5px;
}

.j1t{
    font-weight: bolder;
    margin-left: 20px;
    margin-bottom: 5px;
}
.j2t{
    font-weight: bolder;
    margin-left: 20px;
    margin-bottom: 5px;
}
.j3t{
    font-weight: bolder;
    margin-left: 20px;
    margin-bottom: 5px;
}
.j11{
    margin-left: 20px;
    margin-bottom: 5px;
}
.j22{
    margin-left: 20px;
    margin-bottom: 5px;
}
.j33{
    margin-left: 20px;
    margin-bottom: 5px;
}
.j111{
    margin-left: 50px;
    margin-bottom: 10px;
}
.j222{
    margin-left: 50px;
    margin-bottom: 10px;
}
.j333{
    margin-left: 50px;
    margin-bottom: 10px;
}