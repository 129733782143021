/****************** General ********************/
#content{
  background-color: red;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body {
    font-family: verdana, Helvetica, arial, sans-serif;
    background-color: black;
   
    background-image: url(../public/images/back.jpg);
  
    font-size: 100%;
  }
  header {
    width: 100%;
    margin-bottom: 10px;
  }
  /* header img{
    width: 15%;
    background-color: t;
  } */
  #wrapper {
 
    border-radius: 15px;
    width: 940px;
    margin: 20px auto;
    background: rgba(110, 290, 255, 0.94);
    overflow: hidden;
    padding: 10px;
  }

  #icons {
    float: left;
  }
  nav {
    border-radius: 15px;
    height: 50px;
    background: #05a;
    line-height: 50px;
    margin-bottom: 5px;
  }
  .nav-links {
 
    list-style: none;
    color: rgb(122, 197, 240);
    font-family: Arial, Helvetica, sans-serif;
    display: block;
    justify-content: space-around;
 
    list-style: none; 
    text-align: center;
    margin-top:20peslx
  }
  nav#second {
    height: auto;
  }
  nav ul {
    list-style-type: none;
  }
  nav li {
    float: left; 
    width: 25%;

  } 
  nav a {
    text-decoration: none;

    display: block;

    color: white;
  }
  nav a:hover {
    border-radius: 15px;
    background: #028;
    color: aqua;
  }
  #container {
    margin-top: 20px;
  }
  footer {

    border-radius: 15px;
    height: 50px;
    line-height: 50px;
    background: #05a;
    clear: both;
    color: white;
  }
  footer ul {
    list-style-type: none;
  }
  footer li {
    float: left;
    width: 25%;
  
    text-align: center;
  }
  footer a {
    text-decoration: none;
  
    display: block;
  
    color: white;
  }
  footer a:hover {
    border-radius: 15px;
    background: #028;
    color: aqua;
  }
  img.right {
    float: right;
   
    margin: 0 0 10px 30px;
  }
  img.left {
    float: left;
 
    margin: 0 30px 20px 0;
  }
  .center {
    display: block;
    margin: 20px auto;
    text-align: center;
  }
  .home nav li:nth-child(1) a,
  .about nav li:nth-child(2) a,
  .gallery nav li:nth-child(3) a,
  .contact nav li:nth-child(4) a,
  .quote nav li:nth-child(5) a,
  .login nav li:nth-child(6) .terms nav li:nth-child(7)a {
    background: red;
  }
  main {
    width: 580px;
  
    float: left;
    margin-top: 10px;
  }
  /*********************** thx *******************/
  .thx main {
    width: 920px;
    background: white;
    border-radius: 10px;
    border: 2px solid navy;
    margin-bottom: 10px;
  }
  .thx main p {
    margin-top: 20px;
    margin-left: 20px;
    font-style: italic;
    font-size: 1.2em;
    color: blue;
  }
  .thx main img {
    margin: 0px auto;
  }
  
  #banner img {
    display: block;
    max-width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  }
  
  /************************ Home *****************/
  
  .home aside img {
    max-width: 340px;
  
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .home aside figcaption {
    line-height: 1.6;
    font-size: 1em;
    margin-left: 580px;
    border-radius: 10px;
    border: 3px solid navy;
    margin-bottom: 10px;
    padding: 5px;
  }
  .home main p {
    line-height: 1.7;
    margin-right: 10px;
    font-size: 1.1em;
  }
  .container1 {
    width: 940px;

    clear: both;
    height: 50px;
    font-size: 1.3em;
    margin-left: 50px;
  }
  .tenth {
    padding: 15px;
    border-radius: 10px;
    width: 300px;
    height: 300px;
    margin-left: 300px;
    margin-bottom: 20px;
    border: 4px solid navy;
    /* background-color: #fff; */
  }

  .eleven {
    margin-top: 5px;
    border-radius: 10px;
    width: 200px;
    height: 100px;
    float: left;
  
    margin-left: 42px;
 
    color: white;

    justify-items: center;
    align-items: center;
  }
  
  .eleven img {
    margin-top: 15px;
    width: 220px;
    height: 90px;
    margin-left: -30px;
  
  }
  .eleven h1 {
    text-align: center;
    margin-top: 20px;
  }
  .home .third {

    margin: 0 auto;
  }
  .home video {
    width: 280px;
    height: 280px;
    margin-left: 6px;
  }
  .tenth h4 {
    font-size: 1.1em;
    line-height: 2;
    margin-left: 25px;
  }
  .home nav {
    margin-bottom: 10px;
  }
  .alert {
    padding: 20px;
    background-color: #f44336; 
    color: white;
    margin-bottom: 15px;
  }
  

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
  
  /****************** admin **********************/
  .admin aside {
 
    width: 340px;
    height: 380px;

    float: right;
    margin-top: -20px;
  }
  
  .admin aside img {
    margin-top: 20px;
    display: block;
    max-width: 100%;
  }
  .admin input[type="submit"] {
    border-radius: 10px;
    font-family: sans-serif, fantasy;
    font-size: 1.1em;
    color: red;
  }
  
  
  #logo {
    display: inline;
    background-color: transparent;
  }
  #link {
    border-image: none;
    margin-top: 10px;
    margin-right: 10px;
  }
  #insta {

  
    margin-top: 10px;
    margin-right: 14px;
    border-image-width: 0;
  }
  #whatsapp {
    margin-top: 10px;
  }
  #git {
    width: 110 px;
    height: 110px;
    margin-top: 3px;
    margin-right: 0px;
  }
  #you {
    margin-top: 10px;
    margin-right: 10px;
  }
  
  
  /********************* Gallery ****************/
  .container {
    clear: both;
    overflow: hidden;
  }
  .j{
    width: 900px;
    margin-left: 8px;
  }
  .third {
    width: 300px;
    height: 300px;
    float: left;
  }
  .third img {
    margin-bottom: 15px;
  }
  .third p {
    font-size: 0.9em;
  }
  .third:nth-child(1),
  .third:nth-child(2) {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .forth {
    width: 300px;
    height: 300px;
    float: left;
  }
  .fifth {
    width: 300px;
    height: 300px;
    float: left;
    margin-left: 10px;
  }
  .third h4,
  .forth h4,
  .fifth h4 {
    text-align: center;
  }
  .seven h4,
  .six h4 {
    margin-left: 400px;
    margin-bottom: 10px;
  }
  .seven img,
  .six img,
  .eight img {
    margin-bottom: 15px;
  }
  .six img,
  .eight img {
    margin-top: 15px;
  }
  .forth img {
    margin-bottom: 15px;
  }
  .fifth img {
    margin-bottom: 17px;
  }
  .eight {
    margin-left: 320px;
    margin-bottom: 10px;
  }
  .eight h4 {
    margin-left: 30px;
  }
  
  .j table {
    width: 920px;
  
    border: 2px solid navy;
    border-collapse: collapse;
    margin-bottom: 10px;
  }
  .j td {
   
    width: 25%;
    vertical-align: top;
    padding: 10px;
  }
  .j td img {
    width: 210px;
  }

  .j td h4 {
    font-style: italic;
    font-size: 1em;
    font-family: verdana, arial;
    text-align: center;
  }
  .j th {
    background: navy;
    padding: 10px;
    font-size: 2em;
    color: aqua;
  }
  
  /******************* About **********************/
  .about aside {
    background: navy;
    width: 340px;
 
    float: right;
    margin-top: 10px;
  }
  .about aside p {
    color: skyblue;
    line-height: 1.8;
    font-size: 1em;
    margin: 8px;
  }
  .about figure {
    margin-bottom: 10px;
  }
  .about figcaption {
    background: royal;
    color: white;
    padding: 8px;
    font-size: 13px;
    font-style: italic;
  }
  .about img.left {
    float: left;
    border: 4px navy solid;
    margin: 0px 20px 10px 10px;
  }
  .about figure {
    margin-top:-10px;
  }
  .about figcaption {
  
    background: #000;
    color: #fff;
    padding: 8px;
    font-size: 13px;
    font-style: italic;
  }
  
  /**************** Typograph **********************/
  h1,
  h2 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 20px;
    line-height: 1.4;
    font-size: 1.1em;
  }
  img {
    display: block;
    max-width: 100%;
  }
  h1 {
    font-size: 2.488em;
  }
  h5 {
    margin-left: 10px;
    font-size: 15px;
  }
  h2 {
    font-size: 2.074em;
  }
  h3 {
    font-size: 1.728em;
  }
  blockquote {
    margin-bottom: 20px;
    font-size: 1em;
    font-style: italic;
    border-left: 30px solid #05a;
    padding-left: 30px;
  }
  /***************** Qoute *****************/
  
  .instag {
    width: 150px;
    height: 150px;
    float: left;

    margin-left: 11.5px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .linkedin2 {
    padding: 10px;
    width: 150px;
    height: 150px;
    float: left;
 
    margin-left: 35px;
    margin-bottom: 15px;
    margin-top: -5px;
  }
  .pinte {
    width: 150px;
    height: 150px;
    float: left;
  
    margin-left: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .what {
    width: 150px;
    height: 150px;
    float: left;

    margin-left: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .you2 {
    width: 150px;
    height: 150px;
    float: left;

    margin-left: 35px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .ax1 {
    width: 320px;
    height: 400px;
    float: left;

    margin-left: 15px;
    margin-top: -10px;
  }
  #ax3 {
    width: 320px;
    height: 115px;
    padding: 10px;
    float: left;
    border-radius: 10px;
    border: 3px solid navy;
    margin-left: 15px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .ax2 {
    width: 320px;
    height: 340px;
    float: left;
   
    margin-left: 15px;
    margin-top: -5px;
    margin-bottom: 10px;
  }
  
  /***************Form3 Customer**************/
  #form3 {
    width: 580px;
  
    float: left;
    margin-bottom: 10px;
  }
  #form3 a {
    text-decoration: none;
    color: red;
  }
  #form3 h3 {
    margin-bottom: 20px;
  }
  #form3 label {
    margin-left: 1px;
    width: 100%;
  }
  #form3 input {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #form3 fieldset {
    padding: 10px;
    border-radius: 15px;
    border: 3px solid navy;
  }
  #form3 textarea {
    width: 100%;
    margin-top: 10px;
    height: 60px;
    margin-bottom: 10px;
  }
  #form3 hr {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #form3 ul {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  #form3 li {
    margin-top: 10px;
    list-style-type: none;
    margin-bottom: 10px;
  }
  #form3 input[type="submit"] {
    width: 25%;
    margin-left: 207px;
    font-family: architects_daughterregular;
    border-radius: 8px;
  }
  

  #form3 legend {
    font-size: 1.3em;
    font-style: italic;
    padding: 0 4px;
    color: red;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  #form3 select {
    height: 30px;
    font-size: 1em;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  
  }
  #form3 ul li {
    margin-left: 30px;
  }
  
  #form3 input[type="radio"],
  input[type="checkbox"] {
    width: auto;
    height: auto;
    margin-right: 5px;
    vertical-align: middle;
  }
 
  
  /************* Form style for qoute ***************/
  #form2 {
    width: 580px;
  
    float: left;
    margin-bottom: 10px;
  }
  #form2 label {
    margin-left: 1px;
  }
  #form2 input {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #form2 fieldset {
    padding: 10px;
    border-radius: 15px;
    border: 3px solid navy;
  }
  #form2 textarea {
    width: 100%;
    margin-top: 10px;
    height: 60px;
    margin-bottom: 10px;
  }
  #form2 ul {
    margin-left: 10px;
    margin-bottom: 10px;
  }
  #form2 li {
    margin-top: 10px;
    list-style-type: none;
  }
  #form2 input[type="submit"] {
    width: 25%;
    margin-left: 207px;
    font-family: architects_daughterregular;
  }
 
  #form2 legend {
    font-size: 1.3em;
    font-style: italic;
    padding: 0 4px;
    color: red;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  #form2 select {
    height: 30px;
    font-size: 1em;
    width: 35%;
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 5.5px;
  }
  
  #form2 input[type="radio"],
  input[type="checkbox"] {
    width: auto;
    height: auto;
    margin-right: 5px;
    vertical-align: middle;
  }
  #cont2 input[type="submit"] {
    margin-top: 35px;
    color: white;
    background: red;
    font-family: arial;
    font-size: 1.5em;
    border-radius: 8px;
  }
  

  /******************** Wireframe ************/
  .wireframe img {
    margin: 20px auto;
  }
  .wireframe ol li {
    margin-bottom: 10px;
    margin-left: 20px;
  }
 
  @font-face {
    font-family: "architects_daughterregular";
    src: url("../public/fonts/architectsdaughter-webfont.woff2") format("woff2"),
      url("../public/fonts/architectsdaughter-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
 
  .author h1 {
    color:chocolate;  
    font-size:3em;
    margin-left: 50px;

    } 
  .author h2{
      color:sienna;
      margin-top:5px;
      font-size: 2em
  }
  
  .author h3{ 
      color:#6666aa;  
      margin: 20px 10px 10px 10px;
      font-size:1.5em
  }
  
  .author h4{
      color: white;
      margin:20px;
      font-size:1.2em
  }
  
  div#wrapper22{
    margin-right: 10px;
    width:900px;
    height: 980px;
    margin:20px auto; 
    border: 4px solid white;
    padding:10px;
    background:rgba(200,200,200,.90);

  } 
  
  .author p, hr{
    margin:15px;
 
     font-size:1.2em;   
  }
  .author li{
   
    font-size:1.1em;
    margin:2px;
  }
  .author ul{
    margin-left: 20px;
    list-style-type: none;
  }
  .author img{
    float:right;
    margin:0 0 0 20px;
  }
  .author img.left{
    float:left; 
  }
  .author .body{

            background-image: url(../public/images/73c09671327c76f66fce1c83a337ae0d.jpg);
            background-attachment: fixed;
            font-size:100%
  }
  .author    blockquote{
    margin: 10px 0 10px 20px
  }

  
  .Quote button{
    width: 200px;
    height: 30px;
    border-radius: 10px;
    background-color: rgb(31, 58, 235);
    border-color:rgb(31, 58, 235);
    font-size: 20px;
    font-style:oblique;
    margin-left: 160px;
    color: #fff;
  }
  
  .Quote input{
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
    
  
  .Quote input:focus{
    min-width: 60%;
  }
  .Quote{
    width: 60%;
  
  }
  
  
  .Quote aside {
    background: navy;
    width: 340px;
  
    float: right;
    margin-top: 10px;
  }
  .Quote aside p {
    color: skyblue;
    line-height: 1.8;
    font-size: 1em;
    margin: 8px;
  }
  .Quote figure {
    margin-bottom: 10px;
  }
  .Quote figcaption {
    background: royal;
    color: white;
    padding: 8px;
    font-size: 13px;
    font-style: italic;
  }
  .Quote img.left {
    float: left;
    border: 4px navy solid;
    margin: 0px 20px 10px 10px;
  }
  .Quote figure {
    margin-top:-10px;
  }
  .Quote figcaption {
  
    background: #000;
    color: #fff;
    padding: 8px;
    font-size: 13px;
    font-style: italic;
  } 
 
  label {
    margin-left: 1px;
    width: 100%;
  }
  input {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  fieldset {
    padding: 15px;
  }
  textarea {
    width: 100%;
    margin-top: 10px;
    height: 70px;
    margin-bottom: 10px;
  }
  form ul {
    margin-left: 10px;
    margin-bottom: 10px;
  }

  legend {
    font-size: 1.3em;
    font-style: italic;
    padding: 0 4px;
    color: red;
    margin-bottom: 10px;
    margin-left: 150px;
  }
  select {
    width: 100%;
    height: 30px;
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  input[type="radio"]{
    display: none;
  }
  input[type="radio"] {
    display: inline-block;
    width: 30px;
    height: 15px;
    background: transparent;
    vertical-align: middle;
    border: 2px solid #f56;
    border-radius: 50%;
    padding: 2px;
    margin-bottom: 20px;
  
  }
  /* input[type="radio"] {
    width: 10px;
    height: 10px;
    background: #f56;
    background-clip: content-box;
  } */
  .ax {
  
    width: 340px;
    height: 900px;
    float: right;
    margin-top: 10px;
    /* background-color: red; */
  
  }
  .ax #acr.center{
    /* margin-top: 100px; */
    margin-right: 10px;
    max-height: 100%; 
  
    margin-bottom: 80px;
   width: 100%;
    float: right;
  }
  .ax #poly.center{
        margin-right: 10px;
    max-height: 100%; 
  
    margin-bottom: 80px;
  width: 100%;
    float: right;
  }
  
  .right button{
    width: 90px;
    float: right;
  }
  
  .left button{
    width: 90px;
    float: left;
  }
  .mid button{
    width: 90px;
    background-color: red;
  }
  .emp{
    width: 270px;
    height: 190px;
    float: right;
    background-color: red;
  
  
  }
  .emp img {
    width:250px;
    height: 170px;
  margin-top:10px
  
  }
  .Slider{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .Journal{
    width: 45%;
    float: left;
    margin-left: -140px;
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .Journal2{
    width: 45%;
    float: right;
    margin-right: 40px;
    margin-top: 70px;
  }
  .Date_Input{
    width: 30%;
  


  }
  .Date_Input label{
  
  margin-left:-150px;
}
.Date_Input input{
  margin-left: 0px;
  margin-top: 30px;
  margin-bottom: 20px;
}
  .description{
    width: 60%;
    margin-left: 170px;
  }
  .description button{
    width: 200px;
    height: 30px;
    border-radius: 10px;
    background-color: rgb(31, 58, 235);
    border-color:rgb(31, 58, 235);
    font-size: 20px;
    font-style:oblique;
    margin-left: 160px;
    color: #fff;
  }
  .Products h4 a{
    color:red;
    display: block;
    margin-bottom: 10px;
    text-decoration:none
  }
  .Products h4 a:hover{
  color:#028
  }
  .j img:hover{
    /* margin-top: 10px; */
    
        transform: scale(1.9);
        
}
  .menu-item2{
  
    width: 400px;
    float: left;
    margin-left: 120px;
  }
  .Journalasli p a{
    color: red;
  }
  .Journalasli p a:hover{
    color:#028
  }
  .bal button{
  width:100px ;
  margin-right: 20px;
  background-color: blue;
  color: white;
  height: 30px;
  }
  .clear button{
    width:100px ;
    background-color:blue;
    color: white;
    height: 30px;
  }
  .balClear{
    justify-content: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .des1 button{
    width:100px ;
    margin-right: 20px;
    background-color: blue;
    color: white;
    height: 30px;
    }
    .des2 button{
      width:100px ;
      background-color:blue;
      color: white;
      height: 30px;
    }
    .des{
      justify-content: center;
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      margin-bottom: 20px;
    }
 #outer-container{
  margin-bottom: 60px;
}
.GG_nav{
margin-top: 20px;
}
#outer-container2{
  margin-bottom: 160px;
}
.Account{
  width: 250px;
  margin-bottom: 20px;
}
.Account legend{
  font-size: 1em;
  color: red;
  font-weight: bolder;
  padding: 0 4px;
 font-family:Arial, Helvetica, sans-serif;
  margin-bottom: 10px;
  margin-left: 10px;
}
.h{
/* margin-left: 600px; */
margin-top: -20px;
margin-bottom: 20px;
  width: 450px;
}
.dd h3{
  margin-left: 20px;

}
.dd{
  margin-bottom: -40px;
}
.h31{
  margin-bottom: 10px;
  width: 500px;
  margin-right: 10px;
  text-align: right;
  margin-right: 20px;
  margin-left: 400px; 
}
.h31 h3{
  font-size: 1.1rem;
}

.h h3{
  margin-bottom: 20px;
}
.hac h3{
  margin-bottom: 20px;
}

.calendar {
  /* background:turquoise; */
color:red
} 


.calender td  a{
color: red;
}
.calendar th {
  background: navy;
  padding: 10px;
  font-size: 1em;

} 
.calendar .fc .fc-col-header-cell-cushion { /* needs to be same precedence */
  padding-top: 5px; /* an override! */
  padding-bottom: 5px; /* an override! */
}
.calendar a{
  text-decoration: none !important;
  color: #505363;
}
.fc-daygrid-day-number  {
  color: #337ab7;
  text-decoration: none;
  background-color: navy;
  font-size: 14px
}
.fc-day:hover{
  background:#EEF7FF;
  cursor: pointer;
  transition: all 0.2s linear;
}

.tran p{
  margin-left: 20px;
}

#page-wrap2{
 margin-bottom:-90px ;
}
.transactions h4 a{
  color:red;
  display: block;
  margin-bottom: 10px;
  text-decoration:none
}
.transactions h4 a:hover{
color:#028
}
#outer-container button{
  height: 45px;
  float: right;
  font-weight: bold;
  font-size: 1em;
  border-radius: 10px;
  color:white
}
#page-wrapcust{
  margin-left: 50px;
}

.h4 h4{
  margin-left: 50px;
  margin-bottom: 20px;
  }

.tranCus .ag-pivot-off .ag-header-group-cell {
  font-size: 30px;
  margin-left: 290px;
}
.inv button{
 background-image: url(../public/images/whatsapp.png);
  max-width: 90px;
  height: 85px;
  background-repeat: no-repeat;
  border-radius: 25px;
  border-color:chartreuse;
  float: right;
}

.h h3 a {
  color:red;
  display: block;
  margin-bottom: 10px;
  text-decoration:none;
  margin-left: 8px;
  margin-bottom: -5px;
}
.h h3 a:hover{
  color:#028
}
.addOrder h3 {
  margin-bottom: 20px;
  margin-top: 20px;

}
.addOrder h3 a{
  display: block;
  color: #f44336;
}
.addOrder h3 a:hover{
  color:#028
  }
  #add2Orders{
    width: 180px;
    height: 40px;
    /* background-image: url(../public/images/add.jpg); */
    background-color: #05a;
    color:cornsilk
  }
.orderPage {
    /* background-color: red; */
    height: 550px;
  }


  .noti{
    width: 20px;
   margin-top: -60px;
   margin-left: -55px;
   cursor: pointer;
   position: relative;

  }
  .msgBtn{
    width: 350px;
   
  }
  .counter{
    width: 9px;
    height: 9px;
    /* background-color: darkblue; */
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -4px;
    right: 5px;
  }
  .cell-pass {
    /* text-align: center; */
    font-weight: bold;
    color: #f44336;
  }
  .Hist button{
    width: 150px;
    height: 40px;
    background-color: blue;
    color: white
  }
  .cell-approved {
    font-weight: bold;
    color: #4caf50;
  }
  .fc-view-harness.fc-view-harness-active{
    background-color: #fff;
    color:black
  }

.picture1 {
float: left;
position: absolute;
margin-top: 15px;

}
#logo22{
  position: relative;
  width: 180px;
  height: 80px;
  margin-left: 20px;
  margin-top: -20px;
}