.myBot{
    width:75px;
    height: 50px;
    border-radius:  50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    /* background-color: blue; */
    color: blanchedalmond;
    background-image: url(../public/images/131.jpg);
    margin-right: 15px;
    background-repeat: no-repeat;

}
